.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.yd-confirm-bd {
  margin: 0.4rem 0;
}
.new-address {
  /*设为默认*/
}
.new-address .cell-group {
  width: 100%;
  padding-left: 0.24rem;
  background: #fff;
}
.new-address .cell-group .cell {
  width: 100%;
  height: 0.96rem;
  line-height: 0.96rem;
  border-bottom: 1px solid #d8d8d8;
  overflow: hidden;
}
.new-address .cell-group .cell span {
  font-size: 0.32rem;
  color: #333;
  width: 22%;
  display: inline-block;
}
.new-address .cell-group .cell input {
  width: 78%;
  height: 0.96rem;
  font-size: 0.32rem;
  color: #666;
}
.new-address .cell-group .cell input::placeholder {
  font-size: 0.32rem;
  color: #999;
}
.new-address .yd-cell-left {
  font-size: 0.32rem;
  color: #333;
  width: 19.1%;
  margin-right: 0.24rem;
}
.new-address .yd-cell-left span {
  line-height: 0.96rem;
}
.new-address .yd-cell-right {
  height: 0.96rem;
  width: 80.9%;
}
.new-address .yd-cell-right input {
  font-size: 0.32rem;
  color: #666;
  line-height: 100% !important;
  height: 100%;
}
.new-address .yd-cell-right input::placeholder {
  font-size: 0.32rem;
  color: #999;
}
.new-address .yd-cell-right input[type="text"] {
  height: 0.96rem;
  line-height: 0.96rem !important;
}
.new-address .yd-cell:after {
  height: 0;
}
.new-address .select-box input {
  color: var(--main-color) !important;
  width: 1.02rem;
  height: 0.62rem;
  border-radius: 0.31rem;
}
.new-address .select-box .yd-switch:after {
  width: 0.56rem;
  height: 0.56rem;
}
.new-address .area-box {
  font-size: 0.32rem;
  color: #333;
  min-height: 0.96rem;
  padding-right: 0.56rem;
  background: white url(../../../assets/ic_arrow_right_gray@2x.png) no-repeat 97% 50%;
  background-size: 0.26rem 0.26rem;
  border-bottom: 1px solid #ddd;
}
.new-address .area-box span {
  width: 23.5%;
}
.new-address .area-box .area-string,
.new-address .area-box no-area {
  height: 100%;
  width: 79.2%;
  background: #fff;
  border: none;
  line-height: 0.44rem;
  color: #666;
}
.new-address .area-box .no-area {
  color: #999;
}
.new-address .yd-cell-box {
  margin-bottom: 0.24rem;
}
.new-address footer {
  margin-top: 0.6rem;
}
